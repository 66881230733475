import { GET_CONFIG } from "../type";

interface InitialConfig extends Percentage {};

const initialState: InitialConfig = {
  percentage_admin: 0,
  percentage_comm: null,
  phone_number_received: '',
  store_payment: 'automatic',
  amount_shipping: 0,
};

export default (state = initialState, action: any): InitialConfig => {
  switch (action.type) {
    case GET_CONFIG:
      return { ...state, ...action.payload };
    default:
      return state;
  };
};